import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServicePagePortal from '../../components/service-page-portal';

class RepairService extends Component {
    render() {
        return (
            <Layout>
                <div className="repair-service-page">
                    <HelmetEx>
                        <div metadata="title">Blind, Shade, Shutter Repair | Window covering Alternation | Elmwood Park NJ</div>
                        <div metadata="keywords">blind,shade,shutter,repair,window,covering,alternation,bergen,county,nj</div>
                        <div metadata="description">
                            Count on us to repair your window coverings quickly 
                            and professionally -whether you purchased from us or 
                            from another dealer.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>REPAIR SERVICE</h1>
                                            <h3>
                                                Call on our experts to take down or rehang your window treatments
                                                professionally and quickly
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <ServicePagePortal activePath={"/services/repair-service"}>
                                <section>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>BLIND AND SHADE REPAIR</h4>
                                        </div>
                                        <div className="col-12">
                                            <p className="first-child-p pt-4 pt-lg-0">
                                                Count on us to repair your window coverings quickly and professionally
                                                -whether you purchased from us or from another dealer.
                                            </p>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h4>SHUTTER REPAIR</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Count on us to repair your shutters or replace parts - to get tour shutters working smoothly again.
                                    </p>
                                </section>
                                <section>
                                    <h4>WINDOW COVERING ALTERATIONS</h4>

                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Call on us to update the size or design of your draperies and other soft window fashions.
                                    </p>
                                </section>
                                <section>
                                    <h4>WINDOW COVERING TAKE-DOWN AND REHANG SERVICE</h4>
                                    <p className="first-child-p pt-4 pt-lg-0">
                                        Call on our experts to take down or rehang your window treatments professionally and quickly.
                                    </p>
                                </section>
                            </ServicePagePortal>
                        </div>
                    </section>
                </div>
            </Layout >
        )
    }
}

export default RepairService;